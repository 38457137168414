<template>
  <Button
    :href="href"
    :color="color"
    :layout="layout"
    :rounded-corners="rounded"
  >
    {{ text }}
  </Button>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      href() {
        return this.getIngredient("link")?.linkUrl
      },
      text() {
        return this.getValue("link")
      },
      color() {
        return this.getValue("color")
      },
      layout() {
        return this.getValue("layout")
      },
      rounded() {
        return this.getValue("rounded_corners")
      },
    },
  }
</script>
